const Tecnologia = ()=>{
    return(
        <div className='text-left h-full pb-24 w-full md:w-2/3 px-4 md:px-0'>
            <div className='text-left w-full py-12 sm:w-full h-auto transformacion-container'>
                <div className="inline-block bg-yellow-500 text-black px-2 text-xs font-semibold mb-4" style={{ borderRadius: '0.25rem', fontSize: '0.6rem' }}>
                    NOSOTROS
                </div>
                <h1 className="text-3xl lg:text-5xl font-bold mb-4 transformacion-title" style={{ lineHeight: '1.2' }}>
                    Tecnología que <br /> Inspira, {' '}
                    <span className="relative">
                        <span className="z-10 relative bg-yellow-500 px-1 py-0 rounded-md text-black" style={{ height: '1.1em', display: 'inline-block', textShadow: 'black 0px 0px 0px, black 0px 0px 1px' }}>
                            Soluciones
                        </span>
                        <br />
                        <span className="z-10 relative bg-yellow-500 px-1 py-0 rounded-md text-black" style={{ height: '1.1em', display: 'inline-block', textShadow: 'black 0px 0px 0px, black 0px 0px 1px' }}>
                            que Transforman.
                        </span>
                    </span>
                </h1>
            </div>
        </div>
    )
}

export default Tecnologia;