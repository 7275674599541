import React from 'react';
import aboutusImg from '../images/about_us_bg.svg';
import agilidad from "../images/aboutus/agilidad.svg";
import personalizacion from "../images/aboutus/personalizacion.svg";
import compromiso from "../images/aboutus/compromiso.svg";
import integracion from "../images/aboutus/integracion.svg";

const AboutUs = () => {
  return (
    <div
      className="relative bg-cover bg-center text-white"
      style={{ backgroundImage: `url(${aboutusImg})` }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 max-w-6xl mx-auto py-16 px-4 text-center">
        <h2 className="text-xs md:text-sm uppercase tracking-widest mb-2 font-bold">02 | Nosotros</h2>
        <h1 className="text-3xl md:text-4xl font-bold mb-4 leading-">
          Tecnología que <br />
          <span className="">Inspira, </span><span className='bg-yellow-500 rounded-lg text-black px-2 font-black'> Soluciones</span> <br />
          <span className='bg-yellow-500 rounded-lg text-black font-black px-2'>que Transforman.</span>
        </h1>
        <p className="text-base md:text-lg mb-8">
          Ofrecemos servicios de consultoría especializados en transformación digital, diseñados
          para ayudar a las empresas a adaptarse y prosperar en la era digital. Analizamos las
          operaciones existentes y diseñamos estrategias personalizadas que incorporan las últimas
          tecnologías para optimizar procesos, mejorar la eficiencia y posicionar a las empresas en
          la vanguardia de sus industrias.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mb-8">
          <div className="flex flex-col items-center md:flex-row font-bebas">
            <img
              src={personalizacion}
              alt="Personalización Innovadora"
              className="mb-2 h-32 w-32"
            />
            <span className="text-sm md:text-xl text-center">PERSONALIZACIÓN<br/> INNOVADORA</span>
          </div>
          <div className="flex flex-col items-center md:flex-row font-bebas">
            <img
              src={compromiso}
              alt="Compromiso con la Excelencia"
              className="mb-2 h-32 w-32"
            />
            <span className="text-sm md:text-xl text-center">COMPROMISO CON <br/>LA EXCELENCIA</span>
          </div>
          <div className="flex flex-col items-center md:flex-row font-bebas">
            <img src={agilidad} alt="Agilidad y Adaptabilidad" className="mb-2 h-32 w-32" />
            <span className="text-sm md:text-xl text-center">AGILIDAD Y <br/>ADAPTABILIDAD</span>
          </div>
          <div className="flex flex-col items-center md:flex-row font-bebas">
            <img src={integracion} alt="Integración Perfecta" className="mb-2 h-32 w-32" />
            <span className="text-sm md:text-xl text-center">INTEGRACIÓN<br/> PERFECTA</span>
          </div>
        </div>
        <button className="bg-yellow-500 text-black py-2 px-4 rounded-2xl w-64">Ver Más</button>
      </div>
    </div>
  );
};

export default AboutUs;
