import React from 'react';
import flowmaster from "../images/services/flowmaster_services.svg";
import protech from "../images/services/protech_services.svg";
import ai from "../images/services/ai_services.svg";
import datavision from "../images/services/datavision_services.svg";
import cloud from "../images/services/cloud_services.svg";

const Hero = () => {
  return (
    <div className="text-left py-12 justify-center w-full md:w-2/3 mt-24 px-4">
      <div className='w-3/4 md:w-6/7 items-left'>
        <h1 className="text-4xl text-white md:text-6xl font-semibold mb-6">La innovación comienza cuando 
            <strong className='font-black'> cuestionamos lo establecido</strong>
            </h1>
            <button
            className="bg-yellow-500 text-blue-900 rounded-2xl px-8 md:px-12 py-2 h-10 text-lg font-bold"
            onClick={() => window.open('https://qrtwebsite.azurewebsites.net/demo_bi/f0a5bdce-d5c3-4d11-8486-902a491e16b9', '_blank')}
          >
            Ver Demostración
          </button>
      </div>
      <div className='grid grid-cols-2 gap-4 md:flex md:flex-wrap justify-between text-white mt-12 md:mt-24 mb-12'>
        <div className="text-left flex items-center mb-6 md:mb-0 font-bebas">
          <img src={ai} alt="IA Factory" className="h-12 w-12 mb-2"/>
          <p className='ml-4 font-bold py-4'>IA <br />Factory</p>
        </div>
        <div className="text-left flex items-center mb-6 md:mb-0 font-bebas">
          <img src={protech} alt="Protech Insight" className="h-12 w-12 mb-2"/>
          <p className='ml-4 font-bold py-4'>Protech <br /> Insight</p>
        </div>
        <div className="text-left flex items-center mb-6 md:mb-0 font-bebas">
          <img src={flowmaster} alt="Flowmaster Automation" className="h-12 w-12 mb-2"/>
          <p className='ml-4 font-bold py-4'>Flowmaster<br />Automation</p>
        </div>
        <div className="text-left flex items-center mb-6 md:mb-0 font-bebas">
          <img src={datavision} alt="Datavision BI" className="h-12 w-12 mb-2"/>
          <p className='ml-4 font-bold py-4'>Datavision<br /> BI</p>
        </div>
        <div className="text-left flex items-center w-full sm:w-1/2 md:w-1/5 font-bebas">
          <img src={cloud} alt="Cloud Nexus" className="h-12 w-12 mb-2"/>
          <p className='ml-4 font-bold py-4'>Cloud <br />Nexus</p>
        </div>
      </div>
    </div>
  );
}

export default Hero;
