import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import emailIcon from '../images/email.svg';
// import DarkModeToggle from './DarkModeToggle';
import spanishIcon from '../images/espanol.svg'; // Asegúrate de que la ruta sea correcta
import qrtLogo from "../images/qrt_logo.png";
import wasap from "../images/wasap.svg";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-transparent w-full md:w-2/3 text-white flex flex-col py-2">
      <div className="flex justify-between items-center border-white border-b pb-2 px-4">
        <div className='flex items-center'>
          <img src={emailIcon} alt="Email Icon" className="h-6 w-6 mr-2" />
          <h2 className="text-[12px] font-medium leading-[15.6px] text-left">
            contacto@qrtsolutions.com
          </h2>
        </div>
        <div className="relative flex items-center">
          {/* <img src={spanishIcon} alt="Español Icon" className="h-4 w-4 mr-2" /> */}
          <select className="bg-transparent text-white text-sm appearance-none pr-6">
            {/* <option value="es" selected>Español</option> */}
            {/* <option value="en">English</option> */}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
          </div>
          <button 
            className="bg-transparent border-2 border-white text-white text-sm font-bold py-1 px-4 md:px-8 rounded-lg ml-4"
            onClick={() => window.open('https://qrtwebsite.azurewebsites.net/', '_blank')}
          >
            Portal Clientes
          </button>
        </div>
        <button 
          className="block md:hidden text-white text-2xl"
          onClick={toggleDropdown}
        >
          &#9776; {/* Ícono de hamburguesa */}
        </button>
      </div>
      <div className={`flex-col md:flex md:flex-row justify-between items-center px-4 md:px-0 mt-2 ${isOpen ? 'flex' : 'hidden'} md:flex`}>
        <img src={qrtLogo} alt="QRT Logo" className="h-20 md:h-32 -ml-2 md:-ml-12 -mt-2 md:-mt-6" />
        <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6 -mt-4 md:-mt-8">
        {/* <DarkModeToggle /> */}
          <Link to="/" className="hover:text-zinc-300 font-bold">Inicio</Link>
          <Link to="/servicios" className="hover:text-zinc-300 font-bold">Servicios</Link>
          <Link to="/nosotros" className="hover:text-zinc-300 font-bold">Nosotros</Link>
          {/* <a href="#" className="hover:text-zinc-300 font-bold">Noticias</a> */}
          {/* <a href="#" className="hover:text-zinc-300 font-bold">Contacto</a> */}
          <button className="bg-transparent flex items-center justify-around w-36 border-yellow-500 border text-yellow-500 px-4 py-1 rounded-3xl hover:bg-yellow-600"

            onClick={() => window.open('https://wa.me/message/2KDGNSCDPMTRC1', '_blank')}
          >

            <img src={wasap} alt="WhatsApp Icon" className="h-6 w-4"/>
            Hablemos
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
