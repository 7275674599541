import React from 'react'
import serviciosBG from '../../images/servicios_bg.svg'
import Header from '../../components/Header'
import Transformacion from '../../components/Transformacion'
import AiFactory from '../../components/AiFactory'
import DataVision from '../../components/DataVision'
import CloudNexus from '../../components/CloudNexus'
import Footer from '../../components/Footer'
const Servicios = () => {
  return (
    <div className='h-fit flex flex-col'>
    <div
  className="bg-blue-900 h-[70vh] text-white p-8 flex flex-col items-center"
  style={{ backgroundImage: `url(${serviciosBG})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <Header />
        <Transformacion />
    <div className='flex flex-col h-[300vh]'>
    <AiFactory />   
    <DataVision />
    <CloudNexus />
    <Footer/>
    </div>
        
    </div>
    
    </div>
  )
}

export default Servicios
