import React from 'react'
import portadaNosotros from '../../images/nosotros/Portada_bg.png'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Tecnologia from '../../components/Tecnologia'
import Somos from '../../components/Somos'
import Equipo from '../../components/Equipo'
import Clientes from '../../components/Clientes'

const Nosotros = () => {
    return (
        <div className='h-fit flex flex-col'>
            <div
                className="bg-blue-900 h-[80vh] text-white p-8 flex flex-col items-center"
                style={{ backgroundImage: `url(${portadaNosotros})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <Header />
                <Tecnologia/>
                <div className='flex flex-col h-[300vh]'>
                <Somos/>
                <Equipo/>
                <Clientes/>
                <Footer />
                </div>
            </div>
        </div>
    
    )
}
export default Nosotros