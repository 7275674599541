import React from 'react';
import ImgArticle from '../images/Homebg.png'

function Articulo() {
  return (
    <div className="relative bg-black text-white rounded-lg overflow-hidden shadow-md">
      <img
        src={ImgArticle}
        alt="Articulo"
        className="w-full h-48 object-cover opacity-75"
      />
      <div className="absolute inset-0 flex flex-col justify-end p-4 bg-gradient-to-t from-black via-transparent to-transparent">
        <div className="flex justify-between items-center">
          <span className="bg-blue-500 text-white px-2 py-1 text-xs rounded">Hace 12 horas.</span>
          <span className="bg-gray-200 text-gray-800 px-2 py-1 text-xs rounded">Categoría</span>
        </div>
        <h3 className="mt-4 text-sm font-bold">
          Lorem ipsum dolor sit amet consectetur adipiscing elit
        </h3>
        <p className="text-xs mt-1">Publicado por QRT SOLUTIONS.</p>
        <button className="my-4 font-semibold text-white text-xs underline">LEER MÁS</button>
      </div>
    </div>
  );
}

export default Articulo;