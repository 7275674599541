import { Navigate, Route, Routes, useParams,useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Home from "../pages/Home";
import React from 'react'
import Servicios from "../pages/Servicios";
import Nosotros from "../pages/Nosotros";
import Noticias from "../pages/Noticias";
import "./styles.css"
import Login from "../pages/Login";
import ContactsPage from "../components/ContactsPage";
import ContactDetail from "../components/ContactDetailPage";


function GeneralRoutes(){
  const location = useLocation();
  return (
    <TransitionGroup>
    <CSSTransition
      key={location.pathname}
      timeout={400}
      classNames="fade"
    >

        <Routes location={location}>
            <Route path="/" element={<Home />} />
            <Route path="/servicios" element={<Servicios />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="/noticias321" element={<Noticias />} />
            <Route path="/login321" element={<Login />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route path="/contact-details/:id" element={<ContactDetail />} />
        </Routes>
    </CSSTransition>
    </TransitionGroup>
  )
}

export default GeneralRoutes
