import React from 'react';
import ImgArticle from '../images/Homebg.png';
import IMGSearch from '../images/noticias/search.svg';

function Sidebar() {
  return (
    <div className="w-80 p-4">
      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Buscar"
          className="w-full p-2 pr-10 rounded-lg border border-gray-300 text-gray-700 focus:outline-none focus:ring focus:border-blue-300"
        />
        <button className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-blue-500 text-white rounded-sm p-1 px-3">
          <img src={IMGSearch} alt="Search" className="w-5 h-5" />
        </button>
      </div>
      <h2 className="text-lg font-bold text-white bg-blue-500 px-4 py-2 rounded-t-lg">
        Artículos Destacados
      </h2>
      <div className="bg-gray-100 rounded-b-lg p-4 space-y-4">
        {Array(6).fill(0).map((_, index) => (
          <div key={index}> {/* Se añade una clave única para cada elemento */}
            <div className="flex items-start space-x-4 py-2">
              <img
                src={ImgArticle}
                alt="Articulo"
                className="w-16 h-16 object-cover rounded-md"
              />
              <div className="text-left">
                <h3 className="text-xs font-semibold text-gray-800 leading-tight">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit
                </h3>
                <p className="text-gray-500" style={{fontSize: '0.6rem'}}>Publicado por QRT SOLUTIONS.</p>
                <button className="text-blue-500 font-semibold mt-1" style={{fontSize: '0.6rem'}}>LEER MÁS{'>'}</button>
              </div>
            </div>

            {index !== Array(6).fill(0).length - 1 && (
              <div className="border-b border-gray-300 my-2"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Sidebar;
