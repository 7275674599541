import React from 'react'
import rightImage from "../images/datavision_right.svg"
import leftImage from "../images/datavision_left.svg"


const DataVision = () => {
  return (
    <div className="max-w-7xl w-2/3 mx-auto p-4">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4  rounded-lg p-4">
      <div className="flex flex-col items-start">
        <h2 className="text-3xl font-bold bg-blue-700 text-white px-8 leading-6 py-1 rounded-xl">Datavision BI</h2>
        <p className="mt-2 text-black text-justify leading-9">
          La inteligencia de negocios (BI) es fundamental para la <strong>toma de decisiones informadas</strong>. Desarrollamos soluciones de BI que permiten a las empresas <strong>recopilar, analizar y visualizar datos de manera efectiva</strong>. Desde dashboards interactivos hasta informes detallados, nuestras soluciones de BI transforman datos en información accionable, permitiendo a los líderes empresundefinedles tomar decisiones estratégicas con confianza. Utilizamos herramientas de análisis avanzadas para <strong>identificar patrones, tendencias y oportunidades, ayudando a las empresas a maximizar su rendimiento y alcanzar sus objetivos</strong>.
        </p>
      </div>
      <div className="flex justify-center">
        <img src={rightImage} alt="Datavision BI Image" className="rounded-lg"/>
      </div>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4  rounded-lg p-4 mt-4">
      <div className="flex justify-center">
        <img src={leftImage} alt="Flowmaster Automation Image" className="rounded-lg"/>
      </div>
      <div className="flex flex-col items-start">
        <h2 className="text-3xl  text-black font-bold w-32 px-8 font-bold">Flowmaster</h2> 
        <span className="bg-blue-700 text-white px-8 py-1 rounded-2xl font-bold text-3xl">Automation</span>
        <p className="mt-2 text-black text-justify leading-9">
          La automatización de procesos es clave para <strong>mejorar la eficiencia y reducir los costos operativos</strong>. En QRT, utilizamos tecnologías avanzadas para <strong>automatizar tareas repetitivas y complejas</strong>, liberando a las personas para que se enfoquen en actividades de mayor valor. Desarrollamos soluciones de automatización personalizadas que integran sistemas y procesos, <strong>optimizando el flujo de trabajo y mejorando la precisión y la velocidad en la ejecución de tareas críticas</strong>. Desde la automatización de procesos administrativos hasta la integración de sistemas de producción, nuestras soluciones están diseñadas para transformar la manera en que las empresas operan.
        </p>
      </div>
    </div>
  </div>
  )
}
export default DataVision
