import React from 'react';
import Articulo from './Articulo';
import Sidebar from './Sidebar';
import Slider from '../images/noticias/sliders.svg'

const ArticleDashboard = () => {
  return (
    <div className="min-h-screen">
      <div className="container mx-auto p-6">
      {/* Barra de navegación de categorías */}
        <div className="flex items-center justify-start space-x-1 p-6 bg-white">
          <img src={Slider} alt="Slider" className='w-6'/>
          <button className="font-extrabold text-xs text-gray-600 underline">Todo</button>
          <button className="text-gray-500 font-bold text-xs">| Categoria 1 |</button>
          <button className="text-gray-500 font-bold text-xs">Categoria 2 |</button>
          <button className="text-gray-500 font-bold text-xs">Categoria 3</button>
        </div>
        <div className="flex flex-wrap -mx-4">
          {/* Grid de artículos */}
          <div className="w-full lg:w-3/4 px-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {Array.from({ length: 9 }).map((_, index) => (
                <Articulo key={index} />
              ))}
            </div>
            {/* Paginación */}
            <div className="flex justify-center mt-8 space-x-2 text-sm text-gray-600">
              <button className="px-2 py-1 rounded bg-blue-600 text-white">1</button>
              {[2, 3, 4, 5, 6].map((number) => (
                <button key={number} className="px-2 py-1 rounded hover:bg-blue-100">
                  {number}
                </button>
              ))}
            </div>
          </div>

          {/* Sidebar de artículos destacados */}
          <div className="w-full lg:w-1/4 px-4">
            <Sidebar />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArticleDashboard;
