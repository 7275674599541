import React from 'react';
import wasap from '../images/wasap.svg';
import qrtLogo from '../images/favicon_QRT_white.png';

const Footer = () => {
  return (
    <div className="bg-[#0B0D2C] text-white py-8  items-center flex flex-col">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between items-start md:items-center px-4 space-y-8 md:space-y-0">
        <div className="flex flex-col items-start mb-6 md:mb-0">
          <img src={qrtLogo} alt="Logo" className="mb-4 h-24"/>
          <p className="text-sm">Data Driven Solutions</p>
        </div>
        <div className="flex flex-col items-start mb-6 md:mb-0">
          <h3 className="font-semibold mb-2">Menu</h3>
          <ul className="space-y-2">
            <li><a href="#" className="hover:underline">Inicio</a></li>
            <li><a href="#" className="hover:underline">Servicios</a></li>
            <li><a href="#" className="hover:underline">Nosotros</a></li>
            {/* <li><a href="#" className="hover:underline">Noticias</a></li>
            <li><a href="#" className="hover:underline">Contacto</a></li> */}
          </ul>
        </div>
        <div className="flex flex-col items-start mb-6 md:mb-0">
          <h3 className="font-semibold mb-2">Hablemos</h3>
          <p className="mb-1">+56 9 9679 4243</p>
          <p className="mb-1">nchinchon@qrtsolutions.com</p>
          <p className="mb-1">dnehgme@qrtsolutions.com</p>
          <p>Cerro el Plomo 5420<br/>Las Condes, Santiago.</p>
        </div>
        <div className="flex flex-col items-start">
          <h3 className="font-semibold mb-2">Hablemos</h3>
          <button className="bg-transparent flex items-center justify-around w-36 border-yellow-500 border text-yellow-500 px-4 py-1 rounded-3xl hover:bg-yellow-600"

            onClick={() => window.open('https://wa.me/message/2KDGNSCDPMTRC1', '_blank')}
          >

            <img src={wasap} alt="WhatsApp Icon" className="h-6 w-4"/>
            Contactanos
          </button>
          {/* <div className="flex items-center space-x-2">
            <a href="#" className="hover:underline">Ir Arriba ↑</a>
            <button className="bg-zinc-200 text-black p-2 rounded-full">
              <img src="https://placehold.co/20x20" alt="Theme Toggle"/>
            </button>
          </div> */}
        </div>
      </div>
      <div className="bg-trasparent text-white text-center py-2 mt-8">
        <p className="text-xs">© QRT Solutions 2024 Todos los derechos reservados.</p>
      </div>
    </div>
  );
}

export default Footer;
