import React from 'react'
import rocketImg from '../images/rocket.svg'
import rocketIconImg from '../images/rocket_icon.svg'
const Services = () => {
  return (
    
    <div className='h-auto'> 
    
    <div className='bg-[#F0F0F0] flex  flex-col h-auto w-screen justify-center items-center rounded-b-[200px]'>
    <div className='bg-white rounded-xl flex  -mt-8 z-10 w-2/3'>
            
            <div className='bg-white items-left  flex flex-col rounded-xl p-4'> 
                    <h2 className="text-2xl font-bold text-black text-left ">
                    Lleva tu negocio al siguiente nivel
                    </h2>
                    <p className="text-zinc-700  mt-2 text-left">
                    Nuestro enfoque es integral, abarcando desde la evaluación inicial hasta la implementación
                    y el seguimiento, asegurando que cada etapa del proceso esté alineada con los objetivos
                    estratégicos del cliente.
                    </p>              
              </div>
                <div className="border-l border-zinc-300 h-1/4 mx-6 py-8 px-4  mt-2">
                    <button className="bg-yellow-500 text-black font-semibold py-2 px-4 rounded-full">
                        Contáctanos
                    </button>
                    </div>
              </div>
        <div className="flex flex-col md:flex-row items-center text-justify  w-2/3 mt-12 ">
            <div className=" bg-trasparent pr-12">
              <p className="text-blue-600  text-sm font-semibold">01 | SERVICIOS</p>
              <h2 className="text-2xl md:text-5xl font-semibold  text-zinc-900 mt-2">
                        Transformación digital y el 
                        {/* </h2>
                        <h2 className="text-2xl md:text-xl font-bold text-zinc-900 mt-2"> */}
                        <strong> poder de la innovación</strong>
                        </h2>
                <p className="text-black leading-8  mt-4">
                  En <span className="font-bold">QRT Solutions</span>, desbloqueamos el poder de tus datos. Con un enfoque pionero en <span className="font-bold">análisis de datos</span> e <span className="font-bold">inteligencia artificial</span>, convertimos complejidades en claridad, impulsando a las empresas hacia la <span className="font-bold">eficiencia</span>, la <span className="font-bold">innovación</span> y el éxito sin precedentes. En <span className="font-bold">QRT Solutions</span> te guiaremos en cada paso, asegurando que cada decisión te acerque más a tus objetivos.
                </p>
              <button className="mt-2 border-blue-500 bg-trasparent border-2  text-blue-500  px-8  rounded-lg hover:bg-blue-600 hover:text-white">Más Información</button>
            </div>
        <img src={rocketImg} alt="Rocket" className="w-1/2 md:w-1/3  md:mt-0 "/>
      </div>
    </div>


    <div class="bg-white  py-8 mt-12 mb-12">
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
  <div class="text-center">
    <img src={rocketIconImg} alt="rocket icon" class="mx-auto mb-4"/>
    <h3 class="text-xl font-bold text-blue-600  font-bebas">MODELOS AI</h3>
    <p class="text-zinc-600 ">Incluimos modelos de IA que se adaptan mejor a tu industria, para llevar tu negocio al siguiente nivel.</p>
  </div>
  
  <div class="text-center" >
    <img src={rocketIconImg} alt="rocket icon" class="mx-auto mb-4"/>
    <h3 class="text-xl font-bold text-blue-600  font-bebas">INTELIGENCIA DE NEGOCIOS</h3>
    <p class="text-zinc-600 ">Transformamos grandes volúmenes de datos en información valiosa para la toma de decisiones estratégicas.</p>
  </div>
  
  <div class="text-center">
    <img src={rocketIconImg} alt="rocket icon" class="mx-auto mb-4"/>
    <h3 class="text-xl font-bold text-blue-600  font-bebas">ANÁLISIS AVANZADOS</h3>
    <p class="text-zinc-600 ">Aplicamos técnicas de análisis avanzadas para desglosar complejidades y revelar oportunidades ocultas.</p>
  </div>
  
  <div class="text-center">
    <img src={rocketIconImg} alt="rocket icon" class="mx-auto mb-4"/>
    <h3 class="text-xl font-bold text-blue-600  font-bebas">AUTOMATIZACIÓN Y EFICIENCIA</h3>
    <p class="text-zinc-600 ">Automatizamos procesos para incrementar la eficiencia, reducir errores y maximizar el retorno de inversión.</p>
  </div>
  
  <div class="text-center">
    <img src={rocketIconImg} alt="rocket icon" class="mx-auto mb-4"/>
    <h3 class="text-xl font-bold text-blue-600  font-bebas">SOLUCIONES PERSONALIZADAS</h3>
    <p class="text-zinc-600 ">Diseñamos soluciones a medida que se adaptan perfectamente a las necesidades específicas de su empresa.</p>
  </div>
  
  <div class="text-center">
    <img src={rocketIconImg} alt="rocket icon" class="mx-auto mb-4"/>
    <h3 class="text-xl font-bold text-blue-600  font-bebas">CARACTERÍSTICAS INMEDIATAS</h3>
    <p class="text-zinc-600 ">Ofrecemos soluciones en tiempo real para optimizar la eficiencia operativa y la comprensión de los datos.</p>
  </div>
  
</div>
  </div>
</div>
    </div>
  )
}

export default Services
