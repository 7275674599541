import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContacts = async () => {
      const token = localStorage.getItem("authToken");
      const response = await fetch("https://apis.qrtsolutions.com/users-api/clients/contacts", {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });

      if (response.ok) {
        const data = await response.json();
        setContacts(data);
      } else {
        console.error("Error al obtener los contactos");
      }
    };

    fetchContacts();
  }, []);

  const handleRowClick = (contactId) => {
    navigate(`/contact-details/${contactId}`); // Redirige a la vista de detalle del contacto
  };

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold text-center">Lista de Contactos</h1>
      <table className="table-auto w-full mt-6">
        <thead>
          <tr>
            <th className="px-4 py-2">Nombre</th>
            <th className="px-4 py-2">Correo</th>
            <th className="px-4 py-2">Empresa</th>
            <th className="px-4 py-2">Teléfono</th>
            <th className="px-4 py-2">Tipo de Consulta</th>
            <th className="px-4 py-2">Mensaje</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact) => (
            <tr
              key={contact.id}
              onClick={() => handleRowClick(contact.id)}
              className="cursor-pointer hover:bg-gray-100"
            >
              <td className="px-4 py-2">{contact.name}</td>
              <td className="px-4 py-2">{contact.email}</td>
              <td className="px-4 py-2">{contact.company}</td>
              <td className="px-4 py-2">{contact.phone}</td>
              <td className="px-4 py-2">{contact.query_type}</td>
              <td className="px-4 py-2">{contact.message}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContactList;
