import React, { useState } from "react";
import iconWhatsapp from '../images/whatsapp.svg';
import iconLinkedin from '../images/linkedin.svg';
import iconX from '../images/X.png';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    query_type: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://apis.qrtsolutions.com/users-api/clients/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        alert("Mensaje enviado con éxito.");
      } else {
        alert("Error al enviar el mensaje.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error de red.");
    }
  };

  return (
    <div className="bg-blue-50 min-h-screen flex items-center justify-center p-6">
      <div className="w-full max-w-5xl">
        {/* Section Header */}
        <div className="flex items-center space-x-4">
          {/* Contact Info */}
          <div className="text-start">
            <h2 className="text-blue-500 text-sm font-bold">04 | CONTACTO</h2>
            <h1 className="text-4xl font-bold text-gray-900">HABLEMOS</h1>
          </div>

          {/* Divider Line */}
          <div className="h-10 border-l-2 border-blue-500"></div>

          {/* Social Icons */}
          <div className="flex space-x-4 items-center">
            <img src={iconX} alt="X Icon" className="h-10 w-10 bg-blue-600 rounded-full p-2" />
            <img src={iconLinkedin} alt="LinkedIn Icon" className="h-10 w-10 bg-blue-600 rounded-full p-2" />
            <img src={iconWhatsapp} alt="WhatsApp Icon" className="h-10 w-10 bg-blue-600 rounded-full p-2" />
          </div>
        </div>

        {/* Subtitle */}
        <p className="text-gray-700 my-8 text-start">
          ¿Te interesa saber más? No dudes en dejarnos un mensaje con tus dudas o comentarios.
          Estaremos encantados de ponernos en contacto contigo lo antes posible para brindarte toda la
          información que necesites.
        </p>

        {/* Contact Form */}
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="text"
              name="name"
              placeholder="Nombre"
              onChange={handleChange}
              value={formData.name}
              className="border border-gray-300 p-3 rounded-md w-full"
            />
            <input
              type="email"
              name="email"
              placeholder="Correo Electrónico"
              onChange={handleChange}
              value={formData.email}
              className="border border-gray-300 p-3 rounded-md w-full"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <input
              type="text"
              name="phone"
              placeholder="Número de Contacto"
              onChange={handleChange}
              value={formData.phone}
              className="border border-gray-300 p-3 rounded-md w-full"
            />
            <input
              type="text"
              name="company"
              placeholder="Empresa"
              onChange={handleChange}
              value={formData.company}
              className="border border-gray-300 p-3 rounded-md w-full"
            />
            <select
              name="query_type"
              onChange={handleChange}
              value={formData.query_type}
              className="border border-gray-300 p-3 rounded-md w-full"
            >
              <option value="">Tipo de Consulta</option>
              <option value="consulta">Consulta</option>
              <option value="soporte">Soporte</option>
              {/* Agrega más opciones aquí si es necesario */}
            </select>
          </div>

          <textarea
            name="message"
            placeholder="Mensaje"
            onChange={handleChange}
            value={formData.message}
            className="border border-gray-300 p-3 rounded-md w-full h-32"
          ></textarea>

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-20 rounded-md"
          >
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
