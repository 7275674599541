import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ContactDetail = () => {
  const { id } = useParams(); // Obtener el id de la URL
  const [contact, setContact] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
  
    if (token) {
        fetch(`https://apis.qrtsolutions.com/users-api/clients/contact/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log("Datos del contacto:", data); // Muestra los datos en la consola
          setContact(data);
        })
        .catch(error => console.error('Error al obtener los detalles del contacto:', error));
    } else {
      window.location.href = '/login';
    }
  }, [id]);
  

  if (!contact) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold">{contact.name || "Sin nombre"}</h1>
      <p><strong>Email:</strong> {contact.email || "Sin email"}</p>
      <p><strong>Teléfono:</strong> {contact.phone || "Sin teléfono"}</p>
      <p><strong>Empresa:</strong> {contact.company || "Sin empresa"}</p>
      <p><strong>Tipo de Consulta:</strong> {contact.query_type || "Sin tipo de consulta"}</p>
      <p><strong>Mensaje:</strong> {contact.message || "Sin mensaje"}</p>
    </div>
  );
  
};

export default ContactDetail;
