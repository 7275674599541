const QrtNews = () =>{
    return(
        <div className='flex flex-col flex-1'>
            <div className="w-screen flex justify-center bg-white h-auto">
                <div className="w-full lg:w-5/6 p-8 rounded-lg text-center bg-white">
                    <h1 className="text-5xl font-bold mb-4 text-black">
                        QRT {' '}
                        <span className="bg-blue-500 px-2 py-0 rounded-md text-white inline-block">
                            NEWS
                        </span>
                    </h1>
                    <p className="text-zinc-900 leading-7 md:leading-8 mt-8">
                        Ofrecemos servicios de consultoría especializados en transformación digital, diseñados para ayudar a las empresas a adaptarse y prosperar en la era digital. Analizamos las operaciones existentes y diseñamos estrategias personalizadas que incorporan las últimas tecnologías para optimizar procesos, mejorar la eficiencia y posicionar a las empresas en la vanguardia de sus industrias.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default QrtNews;