import logo from './logo.svg';
import './App.css';
import GeneralRoutes from './routes/General';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <GeneralRoutes />

    </div>
  );
}

export default App;
