import React from 'react'
import portadaNoticias from '../../images/noticias/Noticias_bg.png'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Pulso from '../../components/Pulso'
import QrtNews from '../../components/QrtNews'
import ArticleDashboard from '../../components/ArticleDashboard'

const Noticias = () => {
    return (
        <div className='h-fit flex flex-col'>
            <div
                className="bg-blue-900 h-[80vh] text-white p-8 flex flex-col items-center"
                style={{ backgroundImage: `url(${portadaNoticias})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <Header />
                <Pulso/>
                <div className='flex flex-col h-[300vh]'>
                <QrtNews/>
                <ArticleDashboard/>
                <Footer />
                </div>
            </div>
        </div>
    
    )
}

export default Noticias;