import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Crear un ícono personalizado para el marcador
const customMarkerIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41], // Tamaño del ícono
  iconAnchor: [12, 41], // Punto del marcador que estará en la ubicación
  popupAnchor: [1, -34], // Ubicación del popup relativo al icono
  shadowSize: [41, 41], // Tamaño de la sombra
});

const Map = () => {
  // Coordenadas de Cerro el Plomo 5420, Las Condes, Chile
  const position = [-33.4057311, -70.5745738]; 

  return (
    <MapContainer
      center={position}
      zoom={18} // Zoom más cercano a la ubicación
      style={{ height: '500px', width: '100%' }} // Estilo del mapa
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {/* Marcador en la ubicación específica con el ícono personalizado */}
      <Marker position={position} icon={customMarkerIcon}>
        <Popup>
          Cerro el Plomo 5420, Las Condes, Chile
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
