import sixbellLogo from '../images/nosotros/sixbell-logo.avif'
import BHPLogo from '../images/nosotros/BHP_2017_logo.svg.png'
import CheilWorldwideLogo from '../images/nosotros/Cheil_Worldwide_logo.svg.png'
import amsaLogo from '../images/nosotros/logo-amsa.png'
import chileCompraLogo from '../images/nosotros/logo-chilecompra-original.png'
import MGRecursoLogo from '../images/nosotros/MGRecurso-8@4x1.png'
import patrimoreLogo from '../images/nosotros/patrimore-newlogo.webp'

const Clientes = () => {
    return(
        <>
            <div className="lg:w-1/2 mt-20 p-4 lg:p-8 flex bg-white h-auto">
                <div className="flex-1 flex justify-center">
                    <h1 className="text-4xl font-bold bg-blue-500 px-3 py-1 rounded-md text-white">
                        Nuestros Clientes
                    </h1>
                </div>
            </div>

            <div className="max-w-7xl mx-auto p-5">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-20 items-center justify-items-center">
                <img src={amsaLogo} alt="Perfil" className="w-6/12 lg:w-8/12 opacity-50"/>  
                    <img src={BHPLogo} alt="Perfil" className="w-6/12 lg:w-8/12 opacity-50"/>
                    <img src={CheilWorldwideLogo} alt="Perfil" className="w-6/12 lg:w-8/12 opacity-50"/>
                    <img src={chileCompraLogo} alt="Perfil" className="w-6/12 lg:w-8/12 opacity-50"/>
                    
                </div>
                <div className="mt-20 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-20 items-center justify-items-center">
                    
                    <img src={MGRecursoLogo} alt="Perfil" className="w-6/12 lg:w-8/12 opacity-50"/>
                    <img src={patrimoreLogo} alt="Perfil" className="w-6/12 lg:w-8/12 opacity-50"/>
                    <img src={sixbellLogo} alt="Perfil" className="w-6/12 lg:w-8/12 opacity-50"/>
                </div>
            </div>


        </>
    )
}

export default Clientes;